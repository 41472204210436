import { Box, Center, Heading, Text } from "@chakra-ui/react";
import "./index.css";

function App() {
  return (
    <Box bgColor="#f9f9f9" p="1rem" h="100vh" className="App">
      <Box
        bgColor="white"
        m={["1rem", "2rem", "5rem"]}
        borderRadius="10"
        shadow="base"
      >
        <Center>
          <Heading textColor="gray" ml="1rem" pt={["1rem", "2rem", "4rem"]}>
            Hi, This is <font color="black">Anshul Agarwala</font>'s corner of
            the Internet  👋
          </Heading>
        </Center>
        <Box
          pt={["1rem", "2rem", "3rem"]}
          pb={["1rem", "2rem", "3rem"]}
          pr={["1rem", "3rem", "9rem"]}
          pl={["1rem", "3rem", "9rem"]}
        >
          <Center>
            <Box>
              <Text fontSize={["md", "md", "2xl"]}>
                Anshul is a curious human who has worked with <a href="https://fampay.in/">Fampay India</a>[YC S19]
                as a Software Engineer. He will be interning at <a href="https://motorq.com/"> MotorQ</a> in summer '23 to work on connected car space.
                <br />
                He is currently pursuing his{" "}
                <a href="https://www.linkedin.com/in/anshulagarwala/">
                  Education
                </a>{" "}
                in Computer Science Engineering at Vellore Institute of
                Technology, India.
                <br />
                He loves making products people actually enjoy using.
                Sometimes he builds circuits. Other times he 
                <a href="https://github.com/anshulagx">codes</a>.
                Recently he has been playing with Machine Learning and LLMs to hack something cool.
                <br />
                When all those things prove too frustrating, which is often, he 
                reads philosophy and culture.
                He dumps his random thoughts{" "}
                <a href="https://anshulagx.medium.com/">here</a> and is usually
                active on <a href="https://twitter.com/anshulagx">Twitter</a>,
                where you can reach him.
                <br />
                <br /> You can also email him at the address anshulagx [at]
                gmail (dot) com.
              </Text>
            </Box>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
